import clsx from 'clsx';
import useCookie from 'hooks/useCookie';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { getLocaleInfo } from './getLocaleInfo';
import LanguageList from './LanguageList';
import LanguageListItem from './LanguageListItem';
import classes from './LanguagePicker.module.scss';
import { MdOutlineExpandMore, MdOutlineExpandLess } from 'react-icons/md';

export type Props = {
  className?: string;
};

export default function LanguagePicker({ className }: Props) {
  const router = useRouter();
  const locale = getLocaleInfo(router.locale || 'en-US');
  const [localeOverride, setLocaleOverride] = useCookie('NEXT_LOCALE');
  const [isOpen, setIsOpen] = useState(false);

  function handleLanguagePick(locale: string | undefined) {
    setLocaleOverride(locale);
    setIsOpen(false);
  }

  useEffect(() => {
    if (router.isReady && localeOverride && router.locale !== localeOverride) {
      if (router.locale && document.location.href.includes(router.locale)) {
        document.location.href = document.location.href.replace(router.locale, localeOverride);
      }
    }
  }, [localeOverride, router]);

  return (
    <div className={clsx(classes.root, className)}>
      {isOpen && <LanguageList className={classes.list} onLanguagePick={handleLanguagePick} />}
      {locale && (
        <div className={classes.selector} onClick={() => setIsOpen((prevState) => !prevState)}>
          <LanguageListItem
            languageClassName={classes.selectorListItemLanguage}
            localeInfo={locale}
            checked={false}
          />
          {isOpen ? (
            <MdOutlineExpandLess className={classes.icon} />
          ) : (
            <MdOutlineExpandMore className={classes.icon} />
          )}
        </div>
      )}
    </div>
  );
}
