import clsx from 'clsx';
import { useRouter } from 'next/router';
import { getLocaleInfo, LocaleInfo } from './getLocaleInfo';
import classes from './LanguageList.module.scss';
import LanguageListItem from './LanguageListItem';

export type Props = {
  className: string;
  onLanguagePick: (locale: string | undefined) => void;
};

/**
 * Sort language options by country name, but raise current country to the top.
 */
function localeInfoCompare(
  currentLocale: LocaleInfo,
  a: Required<LocaleInfo>,
  b: Required<LocaleInfo>,
) {
  if (a.country?.native === currentLocale.country?.native) {
    return -1;
  }
  if (b.country?.native === currentLocale.country?.native) {
    return 1;
  }
  if (a.country?.native === b.country?.native) {
    return a.language.native.localeCompare(b.language.native);
  }
  return a.country?.native.localeCompare(b.country?.native);
}

function isPopulatedLocaleInfo(locale: LocaleInfo | null): locale is Required<LocaleInfo> {
  return !!(locale && locale.country && locale.countryEmoji);
}

export default function LanguageList({ className, onLanguagePick }: Props): JSX.Element {
  const router = useRouter();
  const locales = router.locales ?? [];
  const locale = getLocaleInfo(router.locale || 'en-us');
  return (
    <div className={clsx(classes.root, className)}>
      {locales
        .map(getLocaleInfo)
        .filter(isPopulatedLocaleInfo)
        .sort(localeInfoCompare.bind(undefined, locale!))
        .map((info) => (
          <LanguageListItem
            key={info.locale}
            className={classes.languageListItem}
            localeInfo={info}
            checked={info.locale === locale?.locale}
            onLanguagePick={onLanguagePick}
          />
        ))}
    </div>
  );
}
